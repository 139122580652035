import React from "react";
import {useParams} from "react-router";
import WaitForData from "../../../common/data-fetching/WaitForData";
import Page from "../../../common/page/Page";
import useQueryReportById from "./useQueryReportById";
import ReportSummaryBlock from "./ReportSummaryBlock";
import useMutationReportMarkViewed from "../mutate/useMutationReportMarkViewed";
import * as ROUTES from "../../../../constants/routes";
import {Redirect} from "react-router-dom";

export default function ReportSummaryPage() {

  // Fetch report from ID provided in the url params.
  const urlParams = useParams();
  const reportId = urlParams.reportId;
  const {data: report, loading: reportLoading, errors: reportErrors} = useQueryReportById(reportId);
  const {mutate} = useMutationReportMarkViewed();

  // Mark the report as viewed as soon as the report is loaded.
  // Don't do that on the backend side when calling reportById in case the endpoint is called without using the frontend
  React.useEffect(() => {
    if (report && !report.viewed) {
      mutate({
        variables: {
          reportId: report._id
        }
      });
    }
  }, [mutate, report]);

  return (
    <WaitForData loading={reportLoading} errors={reportErrors} onLoaded={() =>
      <Page className="ReportSummaryPage">
        <Page.Title text={report ? report.job.title : ""}/>
        <Page.Content className="spaced-children">
          {report ? <ReportSummaryBlock report={report}/> : <Redirect to={ROUTES.NOT_FOUND}/>}
        </Page.Content>
      </Page>
    }/>
  );
}
