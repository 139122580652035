import React from "react";
import {useParams} from "react-router";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import Page from "../../../common/page/Page";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import useQueryTalentPoolById from "./useQueryTalentPoolById";
import TalentPoolHomeBlock from "./TalentPoolHomeBlock";

export default function TalentPoolHomePage(/*props*/) {

  // Fetch talentPool from ID provided in the url params.
  const urlParams = useParams();
  const talentPoolId = urlParams.talentPoolId;
  const {data: talentPool, loading: talentPoolLoading, errors: talentPoolErrors} = useQueryTalentPoolById(talentPoolId);
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolHomePage");

  return (
    <WaitForData loading={talentPoolLoading} errors={talentPoolErrors} onLoaded={() =>
      <Page className="TalentPoolHomePage">
        <Page.Title text={t("market:home_title")}/>
        <Page.Content className="spaced-children">
          {talentPool ? <TalentPoolHomeBlock talentPoolId={talentPoolId}/> : <Redirect to={ROUTES.NOT_FOUND}/>}
        </Page.Content>
      </Page>
    }/>
  );
}
