import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "candidateMarkResumeViewed";

export const QUERY = gql`
  mutation ${QUERY_NAME} (
    $candidateId: ID!
  ) {
    ${QUERY_NAME} (
      candidateId: $candidateId,
    ) {
      _id
      resumeViewed
    }
  }
`;

export default function useMutationCandidateMarkResumeViewed() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
