import React from "react";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryCandidatesByReportId from "./useQueryCandidatesByReportId";
import useContentModal from "../../../common/modals/useContentModal";
import CandidateDetails from "./CandidateDetails";
import CandidateListItem from "./CandidateListItem";
import CandidatePreview from "./CandidatePreview";
import "./CandidateList.scss";
import Loader from "../../../common/loading-widgets/Loader";
import CardList from "../../common/CardList";

export default function CandidateList(props) {

  const {reportId, reportTitle, reportSubtitle} = props;

  // Modal to show candidate details form
  const {ContentModal: CandidateDetailsModal, show: showCandidateDetailsModal, hide: hideCandidateDetailsModal} = useContentModal("CandidateDetailsModal");

  const {data: candidates, loading: candidatesLoading, errors: candidatesErrors} = useQueryCandidatesByReportId(reportId);
  // Remove null candidates. This can happen after a deletion, before the list is updated
  const nonNullCandidates = candidates ? TypeUtils.ensureArray(candidates).filter(candidate => candidate) : [];

  const onClickCandidate = (candidateId) => {
    showCandidateDetailsModal(getCandidateDetails(candidateId))
  }

  const getCandidateDetails = (candidateId) => {
    return (
      <Loader>
        <CandidateDetails
          onClose={hideCandidateDetailsModal}
          candidateId={candidateId}
          reportTitle={reportTitle}
          reportSubtitle={reportSubtitle}
        />
      </Loader>
    );
  }

  return (
    <>
      {CandidateDetailsModal}
      <div className="CandidateList">
        <WaitForData loading={candidatesLoading} errors={candidatesErrors} onLoaded={() =>
          <CardList items={nonNullCandidates} getItemKey={candidate => candidate._id} renderItem={candidate =>
            <CandidateListItem>
              <CandidatePreview candidate={candidate} onClick={() => onClickCandidate(candidate._id)}/>
            </CandidateListItem>
          }/>
        }/>
      </div>
    </>
  );
};
