import React from "react";
import Modal from "react-bootstrap/Modal";

/**
 * Inject a component into a Modal and provide a callback to show and hide the modal.
 * Call show(content) to display the content.
 * The purpose of this hook is to hide the management of the modal show state.
 * @param className Class name to give to the Modal component
 * @returns {{ContentModal: *, show: (*|(function(): void))}} A modal component and callbacks to show/hide it
 */
export default function useGenericModal(className = "") {

  // Show the modal.
  // Optimization: Define at construction time only
  const show = React.useCallback((Component) => {
    setContent(Component);
    setVisible(true);
  }, []);

  // Hide the modal
  // Optimization: Define at construction time only
  const hide = React.useCallback(() => {
    setVisible(false);
  }, []);

  const [visible, setVisible] = React.useState(false);
  const [content, setContent] = React.useState(null);

  // Wrap the component inside a modal. Memoize it to avoid consumer to re-render on every render of this component
  const GenericModal = React.useMemo(() =>
      <Modal show={visible} onHide={hide} className={"GenericModal " + className}>
        <Modal.Body>
          {content}
        </Modal.Body>
      </Modal>
    , [visible, hide, className, content]);

  // Return the modal with the wrapped component and callbacks to show/hide the modal.
  return {GenericModal, show, hide}
}
