import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./TalentPoolDetailsStats.scss";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryStatsForTalentPool from "./useQueryStatsForTalentPool";
import WaitForData from "../../../common/data-fetching/WaitForData";
import StringUtils from "../../../../utils/StringUtils";
import HtmlUtils from "../../../../utils/HtmlUtils";

export default function TalentPoolDetailsStats(props) {

  const {talentPoolId} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolDetailsStats");

  const {data: stats, loading: statsLoading, errors: statsErrors} = useQueryStatsForTalentPool(talentPoolId);

  return (
    <WaitForData loading={statsLoading} errors={statsErrors} onLoaded={() =>
      <div className="TalentPoolDetailsStats">
        <Row className={"stats-line"}>
          <Col >
            {HtmlUtils.htmlToComponents(t("market:stats_job_count", {count: StringUtils.nullToZero(stats.jobCount)}))}
          </Col>
          <Col>
            {HtmlUtils.htmlToComponents(t("market:stats_candidate_count", {count: StringUtils.nullToZero(stats.candidateCount)}))}
          </Col>
        </Row>
      </div>
    }/>
  );
};
