import StringUtils from "../../../utils/StringUtils";
import TypeUtils from "../../../utils/TypeUtils";
import {ApolloClient} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {createUploadLink} from "apollo-upload-client";

// noinspection JSValidateJSDoc
/**
 * Creates an Apollo client. The URI of the GraphQL server is stored in REACT_APP_GRAPHQL_URI environment variable
 * (see method apolloHttpLink). An Authorization header is added to every request (see method apolloAuthLink).
 *
 * @param tokenRef A React reference to the user token (created with useRef) if authentication is required. Leave undefined otherwise.
 * @param cacheManager Cache manager policies
 * @returns {ApolloClient<NormalizedCacheObject>} Apollo connection to a GraphQL server.
 */
export function createApolloClient(tokenRef, cacheManager) {

  return new ApolloClient({
    link: setContext(_getRequestContextSetter(tokenRef)).concat(_apolloHttpLink),
    cache: cacheManager,
    headers: _getRequestContextSetter(tokenRef),
    connectToDevTools: StringUtils.stringIsTrue(process.env.REACT_APP_APOLLO_CONNECT_TO_DEV_TOOLS)
  });
}

/**
 * Stop all queries
 * @param apolloClient
 */
export const stopClient = (apolloClient) => {
  if (apolloClient)
    apolloClient.stop();
}

// noinspection JSValidateJSDoc
/**
 * Use this when creating an Apollo client to specify the URI of the GraphQL server.
 * @type {ApolloLink} Apollo link
 */
const _apolloHttpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

/**
 * Returns a callback to passed as parameter to apollo-link-context.setContext, that will add headers to the
 * current request, such as an authorization header.
 *
 * @param tokenRef A React reference to the user token (created with useRef) if authentication is required. Leave undefined otherwise.
 * @return {function(*, {headers?: *}): {headers: *}}
 */
const _getRequestContextSetter = (tokenRef) => {

  return (operation, {headers}) => {

    // Make a copy of the headers
    const newHeaders = TypeUtils.assign({}, headers);

    if (tokenRef) {
      // Add an authorization field to the current headers if user is authenticated, otherwise remove it from headers
      // (use the current value of token variable, no matter what was frozen when the useEffect was created)
      const token = tokenRef.current;

      if (token)
        newHeaders.authorization = `Bearer ${token}`;
      else
        delete newHeaders.authorization;
    }

    return {
      headers: newHeaders
    };
  };
};
