import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "poolJobByTalentPoolId";

export const QUERY = gql`
  query ${QUERY_NAME}($talentPoolId: ID!) {
    ${QUERY_NAME}(talentPoolId: $talentPoolId) {
      _id
      name
      candidateCount
      talentPoolId
    }
  }
`;

export default function useQueryPoolJobsForTalentPool(talentPoolId) {

  const variables = {talentPoolId};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {variables});
}
