import React from "react";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useTranslationReporting from "../../useTranslationReporting";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useHistory} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import "./ReportHomeBlock.scss";
import BackgroundImage from "../../../../img/home-page-img.svg";
import Image from "react-bootstrap/Image";
import ActionButtonWithIconGo from "../../../common/widgets/ActionButtonWithIconGo";
import HtmlUtils from "../../../../utils/HtmlUtils";

export default function ReportHomeBlock(props) {

  const {reportId} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportHomeBlock");
  const history = useHistory();

  // Translation contains markup, convert it to React html elements
  const pageContent = HtmlUtils.htmlToComponents(t("reporting:home_content"));

  const onClickReport = () => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.REPORT_SUMMARY, {reportId}))
  }

  return (
    <div className={"ReportHomeBlock"}>
      <Row>
        <Col className={"content-col"}>
          <Row>
            <Col className={"title-col"}>
              {/* Put title in Page content instead of Page.Title to help compute the image offset */}
              <h1>{t("reporting:home_title")}</h1>
            </Col>
          </Row>
          <Row>
            <Col className={"action-row"}>
              <ActionButtonWithIconGo onClick={onClickReport}>{t("reporting:home_view_report_action")}</ActionButtonWithIconGo>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"text-content"}>
                {pageContent}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className={"image-col"}>
          <Image src={BackgroundImage}/>
        </Col>
      </Row>

    </div>
  );
};
