import React from "react";
import Nav from "react-bootstrap/Nav";
import "./Menu.scss";
import {LanguageMenuItem} from "./MenuComponents";

export default function Menu() {

  return (
    <Nav className="Menu">

      <LanguageMenuItem/>

    </Nav>
  );
}
