import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PoolJobDetailsBlock.scss";
import {useHistory} from "react-router";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import JobCategoryList from "../../categories/view/JobCategoryList";
import useQueryTalentPoolById from "../../pools/view/useQueryTalentPoolById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ActionIcon from "../../../common/widgets/ActionIcon";

export default function PoolJobDetailsBlock(props) {

  const {poolJob} = props;

  const {data: talentPool, loading: talentPoolLoading, errors: talentPoolErrors} = useQueryTalentPoolById(poolJob.talentPoolId);
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobDetailsBlock");

  const history = useHistory();

  const goBack = () => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.TALENT_POOL_DETAILS, {talentPoolId: talentPool._id}));
  }

  return (
    <div className="PoolJobDetailsBlock mainRowContainer">
      <WaitForData loading={talentPoolLoading} errors={talentPoolErrors} onLoaded={() =>
        <>
          <Row className={"topRow"}>
            <Col className={"title-col titleRowContainer"}>
              <Row>
                <Col className={"back-button-container"}>
                  <div className={"back-button"}>
                    <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
                  </div>
                  <h1>{talentPool.name}</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2><span className={"job-name"}>{poolJob.name}</span></h2>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <JobCategoryList poolJobId={poolJob._id}/>
            </Col>
          </Row>
        </>
      }/>
    </div>
  );
};
