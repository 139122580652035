import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "poolCandidateByJobCategoryId";

export const QUERY = gql`
  query ${QUERY_NAME}($jobCategoryId: ID!) {
    ${QUERY_NAME}(jobCategoryId: $jobCategoryId) {
      _id
      number
      note
    }
  }
`;

export default function useQueryPoolCandidatesByJobCategoryId(jobCategoryId) {

  const variables = {jobCategoryId};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network"
  });
}
