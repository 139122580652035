import React from "react";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryJobCategoriesForPoolJob from "../useQueryJobCategoriesForPoolJob";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import JobCategoryListItem from "./JobCategoryListItem";
import JobCategoryPreview from "./JobCategoryPreview";
import {useHistory} from "react-router";
import MarketCardList from "../../common/MarketCardList";
import TypeUtils from "../../../../utils/TypeUtils";

export default function JobCategoryList(props) {

  const {poolJobId} = props;

  const {data: jobCategories, loading: jobCategoriesLoading, errors: jobCategoriesErrors} = useQueryJobCategoriesForPoolJob(poolJobId);

  // Remove empty job categories
  const nonEmptyCategories = TypeUtils.ensureArray(jobCategories).filter(category => category.candidateCount > 0);

  const history = useHistory();

  const onClickJobCategory = (jobCategory) => {
    history.push(RouterUtils.injectParamsInRoute(ROUTES.JOB_CATEGORY_DETAILS, {
      talentPoolId: jobCategory.talentPoolId,
      poolJobId: jobCategory.poolJobId,
      jobCategoryId: jobCategory._id
    }))
  }

  return (
    <div className={"JobCategoryList"}>
      <WaitForData loading={jobCategoriesLoading} errors={jobCategoriesErrors} onLoaded={() =>
        <MarketCardList items={nonEmptyCategories} getItemKey={jobCategory => jobCategory._id} renderItem={jobCategory =>
          <JobCategoryListItem>
            <JobCategoryPreview jobCategory={jobCategory} onClick={() => onClickJobCategory(jobCategory)}/>
          </JobCategoryListItem>
        }/>
      }/>
    </div>
  )
};
