import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "candidateByReportId";

export const QUERY = gql`
  query ${QUERY_NAME}($reportId: ID!) {
    ${QUERY_NAME}(reportId: $reportId) {
      _id
      name
      note
    }
  }
`;

export default function useQueryCandidatesByReportId(reportId) {

  const variables = {reportId};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network"
  });
}
