import React from "react";
import {createApolloClient} from "./ApolloHelper";
import {ApolloProvider} from "@apollo/client";

/**
 * Provide an Apollo client with ApolloProvider without "Authorization" headers.
 */
export default function UnauthenticatedApolloProvider(props) {

  const {children, cacheManager} = props;

  // Use a ref to the Apollo client instead of useState so that we can initialize it before the first rendering of children
  // (we cannot pass a null client to ApolloProvider)
  const apolloClient = React.useRef(createApolloClient(undefined, cacheManager));

  // RENDER

  return (
    <ApolloProvider client={apolloClient.current}>
      {children}
    </ApolloProvider>
  );
}
