import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PoolCandidatePreview.scss";
import StringUtils from "../../../../utils/StringUtils";
import ActionDiv from "../../../common/widgets/ActionDiv";
import HtmlUtils from "../../../../utils/HtmlUtils";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

const NOTE_LENGTH = 70;

export default function PoolCandidatePreview(props) {

  const {poolCandidate, onClick} = props;

  const fullNote = StringUtils.nullToEmpty(HtmlUtils.removeHtml(poolCandidate.note)).trim();

  const truncatedNote = fullNote.length > NOTE_LENGTH ?
    fullNote.substring(0, NOTE_LENGTH).concat("...") : StringUtils.nullToEmpty(fullNote);

  const note = truncatedNote.length > 0 ? `"${truncatedNote}"` : "";

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolCandidatePreview");

  return (
    <ActionDiv onClick={() => onClick(poolCandidate._id)} className="PoolCandidatePreview mainRowContainer">
      <Row className={"name-row"}>
        <Col className={"name-col"}>
          {t("market:pool_candidates_details_number_label", {number: poolCandidate.number})}
        </Col>
      </Row>
      <Row className={"note-row"}>
        <Col className={"note-col"}>
          {note}
        </Col>
      </Row>
    </ActionDiv>
  );
};
