import React from "react";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryPoolCandidatesByJobCategoryId from "./useQueryPoolCandidatesByJobCategoryId";
import useContentModal from "../../../common/modals/useContentModal";
import PoolCandidateDetails from "./PoolCandidateDetails";
import PoolCandidateListItem from "./PoolCandidateListItem";
import PoolCandidatePreview from "./PoolCandidatePreview";
import "./PoolCandidateList.scss";
import Loader from "../../../common/loading-widgets/Loader";
import MarketCardList from "../../common/MarketCardList";

export default function PoolCandidateList(props) {

  const {jobCategoryId, title, subtitle1, subtitle2} = props;

  // Modal to show candidate details form
  const {ContentModal: PoolCandidateDetailsModal, show: showPoolCandidateDetailsModal, hide: hidePoolCandidateDetailsModal} = useContentModal("PoolCandidateDetailsModal");

  const {data: poolCandidates, loading: poolCandidatesLoading, errors: poolCandidatesErrors} = useQueryPoolCandidatesByJobCategoryId(jobCategoryId);
  // Remove null candidates. This can happen after a deletion, before the list is updated
  const nonNullCandidates = poolCandidates ? TypeUtils.ensureArray(poolCandidates).filter(candidate => candidate) : [];

  const onClickCandidate = (poolCandidateId) => {
    showPoolCandidateDetailsModal(getCandidateDetails(poolCandidateId))
  }

  const getCandidateDetails = (poolCandidateId) => {
    return (
      <Loader>
        <PoolCandidateDetails
          onClose={hidePoolCandidateDetailsModal}
          poolCandidateId={poolCandidateId}
          title={title}
          subtitle1={subtitle1}
          subtitle2={subtitle2}
        />
      </Loader>
    );
  }

  return (
    <>
      {PoolCandidateDetailsModal}
      <div className="PoolCandidateList">
        <WaitForData loading={poolCandidatesLoading} errors={poolCandidatesErrors} onLoaded={() =>
          <MarketCardList items={nonNullCandidates} getItemKey={candidate => candidate._id} renderItem={candidate =>
            <PoolCandidateListItem>
              <PoolCandidatePreview poolCandidate={candidate} onClick={() => onClickCandidate(candidate._id)}/>
            </PoolCandidateListItem>
          }/>
        }/>
      </div>
    </>
  );
};
