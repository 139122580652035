import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CandidatePreview.scss";
import StringUtils from "../../../../utils/StringUtils";
import ActionDiv from "../../../common/widgets/ActionDiv";
import HtmlUtils from "../../../../utils/HtmlUtils";

const NOTE_LENGTH = 70;

export default function CandidatePreview(props) {

  const {candidate, onClick} = props;

  const fullNote = StringUtils.nullToEmpty(HtmlUtils.removeHtml(candidate.note)).trim();

  const truncatedNote = fullNote.length > NOTE_LENGTH ?
    fullNote.substring(0, NOTE_LENGTH).concat("...") : StringUtils.nullToEmpty(fullNote);

  const note = truncatedNote.length > 0 ? `"${truncatedNote}"` : "";

  return (
    <ActionDiv onClick={() => onClick(candidate._id)} className="CandidatePreview mainRowContainer">
      <Row className={"name-row"}>
        <Col className={"name-col"}>
          {candidate.name}
        </Col>
      </Row>
      <Row className={"note-row"}>
        <Col className={"note-col"}>
          {note}
        </Col>
      </Row>
    </ActionDiv>
  );
};
