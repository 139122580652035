import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PoolJobPreview.scss";
import ActionDiv from "../../../common/widgets/ActionDiv";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";

export default function PoolJobPreview(props) {

  const {poolJob, onClick} = props;

  const {t, tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobPreview");

  return (
    <ActionDiv onClick={() => onClick(poolJob._id)} className="PoolJobPreview mainRowContainer">
      <Row className={"name-row"}>
        <Col className={"name-col"}>
          {poolJob.name}
        </Col>
      </Row>
      <Row className={"candidate-row"}>
        <Col className={"candidate-col"}>
          <span className={"number"}>{StringUtils.nullToZero(poolJob.candidateCount) + " "}</span>{t("market:preview_candidate_count", {count: poolJob.candidateCount})}
        </Col>
      </Row>
    </ActionDiv>
  );
};
