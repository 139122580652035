import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "jobCategoryByPoolJobId";

export const QUERY = gql`
  query ${QUERY_NAME}($poolJobId: ID!) {
    ${QUERY_NAME}(poolJobId: $poolJobId) {
      _id
      name
      candidateCount
      talentPoolId
      poolJobId
    }
  }
`;

export default function useQueryJobCategoriesForPoolJob(poolJobId) {

  const variables = {poolJobId};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {variables});
}
