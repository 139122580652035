// Try to keep declarations here and in App.js in the same order, to improve maintainability

// reporting
export const REPORT_HOME = "/reporting/report/:reportId";
export const REPORT_SUMMARY = "/reporting/report/:reportId/summary";
export const REPORT_DETAILS = "/reporting/report/:reportId/details";

// talent market (always include talent pool ID so that link on logo points to talent pool home)
export const TALENT_POOL_HOME = "/market/pool/:talentPoolId";
export const TALENT_POOL_DETAILS = "/market/pool/:talentPoolId/summary";
export const POOL_JOB_DETAILS = "/market/pool/:talentPoolId/job/:poolJobId";
export const JOB_CATEGORY_DETAILS = "/market/pool/:talentPoolId/job/:poolJobId/cat/:jobCategoryId";

// error page for tests
export const ERROR_TEST = "/error-test";

export const NOT_FOUND = "/not-found";

export const EXOTALENT_HOME = "https://exotalent.ca/:language";
export const LINKED_IN = "https://www.linkedin.com/company/exotalent-sourcing/?viewAsMember=true";
export const MAGELLAN_HOME = "https://magellantalent.ca/:language";
export const ST_AMOUR_HOME = "https://www.st-amour.com/:language";
export const FLAIR_TECH_HOME = "https://www.flairtech.ca/:language";
export const STRATO_EXEC_HOME = "https://www.stratoexec.ca/:language";
export const HUBBLE_HOME = "https://www.hubbletalent.ca/:language";
