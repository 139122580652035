import React from "react";
import "./FooterContainer.scss";
import Container from "react-bootstrap/Container";
import Footer from "./Footer";


export default function FooterContainer(props) {

  return (
    <div className="FooterContainer">
      <Container >
        <Footer/>
      </Container>
    </div>
  );
};
