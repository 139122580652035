import React from "react";
import {useParams} from "react-router";
import WaitForData from "../../../common/data-fetching/WaitForData";
import Page from "../../../common/page/Page";
import useQueryTalentPoolById from "./useQueryTalentPoolById";
import TalentPoolDetailsBlock from "./TalentPoolDetailsBlock";
import * as ROUTES from "../../../../constants/routes";
import {Redirect} from "react-router-dom";

export default function TalentPoolDetailsPage() {

  // Fetch talentPool from ID provided in the url params.
  const urlParams = useParams();
  const talentPoolId = urlParams.talentPoolId;
  const {data: talentPool, loading: talentPoolLoading, errors: talentPoolErrors} = useQueryTalentPoolById(talentPoolId);

  return (
    <WaitForData loading={talentPoolLoading} errors={talentPoolErrors} onLoaded={() =>
      <Page className="TalentPoolDetailsPage">
        <Page.Title text={talentPool ? talentPool.name : ""}/>
        <Page.Content className="spaced-children">
          {talentPool ? <TalentPoolDetailsBlock talentPool={talentPool}/> : <Redirect to={ROUTES.NOT_FOUND}/>}
        </Page.Content>
      </Page>
    }/>
  );
}
