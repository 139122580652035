import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "candidateMarkViewed";

export const QUERY = gql`
  mutation ${QUERY_NAME} (
    $candidateId: ID!
  ) {
    ${QUERY_NAME} (
      candidateId: $candidateId,
    ) {
      _id
      viewed
    }
  }
`;

export default function useMutationCandidateMarkViewed() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
