import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "reportMarkViewed";

export const QUERY = gql`
  mutation ${QUERY_NAME} (
    $reportId: ID!
  ) {
    ${QUERY_NAME} (
      reportId: $reportId,
    ) {
      _id
      viewed
    }
  }
`;

export default function useMutationReportMarkViewed() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
