import {gql} from "@apollo/client";
import useDeferredQuery from "../../../common/data-fetching/useDeferredQuery";

export const QUERY_NAME = "candidateResumeDownloadUrl";

export const QUERY = gql`
  query ${QUERY_NAME}($id: ID!) {
    ${QUERY_NAME}(id: $id)
  }
`;

export default function useLazyQueryResumeDownloadUrl() {

  return useDeferredQuery(QUERY, "", QUERY_NAME, {});
}
