import TypeUtils from "../../../utils/TypeUtils";
import StringUtils from "../../../utils/StringUtils";

/**
 * Convert an ApolloError into a normalized error
 * @param error ApolloError
 * @returns {{}|{name: (string|*), message}} Normalized error with a name and a message property
 */
function convertApolloError(error) {

  if (!error) {
    return {};
  }

  const customName = (error.extensions && error.extensions.exception) ? error.extensions.exception.name : null;

  return ({
    name: !StringUtils.isNullOrEmpty(customName) ? customName : error.extensions.code,
    message: error.message
  })
}

/**
 * Convert errors sent by an Apollo GraphQL server into normalized error objects
 * with a name and a message property.
 * @param error Error as returned by a useQuery or useMutation hook
 * @returns {null|*[]} Array of normalized errors, or null if no error
 */
export default function useGraphQLErrorFormatter(error) {

  if (!error)
    return null;

  // See https://www.apollographql.com/docs/react/data/error-handling/
  const errors = [];

  // Network error, such as no response or validation failed. There is only one.
  if (error.networkError) {
    if (error.networkError.result && !TypeUtils.arrayIsEmpty(error.networkError.result.errors)) {
      // Server responded
      // Example of a response when a validation failed:
      // error.networkError.name = "ServerError"
      // error.networkError.result.errors[0].extensions.code = GRAPHQL_VALIDATION_FAILED
      // error.networkError.result.errors[0].message = "Cannot parse..."
      errors.push(convertApolloError(error.networkError.result.errors[0]));
    } else {
      // Server did not respond
      // error.networkError.name = "TypeError"
      // error.networkError.message = "Failed to fetch"
      errors.push({
        name: error.networkError.name,
        message: error.networkError.message
      })
    }
  }

  // Errors thrown by resolvers. There might be many.
  // Example of a response when a resolver throw a NotFoundError():
  // error.graphQLErrors[0].extensions.code = INTERNAL_SERVER_ERROR
  // error.graphQLErrors[0].extensions.exception.name = "NotFoundError"
  // error.graphQLErrors[0].message = ""

  if (!TypeUtils.arrayIsEmpty(error.graphQLErrors)) {
    error.graphQLErrors.forEach(e => errors.push(convertApolloError(e)));
  }

  return errors;
}
