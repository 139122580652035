import React from "react";
import {useParams} from "react-router";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import Page from "../../../common/page/Page";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import useQueryReportById from "./useQueryReportById";
import ReportHomeBlock from "./ReportHomeBlock";

export default function ReportHomePage(/*props*/) {

  // Fetch report from ID provided in the url params.
  const urlParams = useParams();
  const reportId = urlParams.reportId;
  const {data: report, loading: reportLoading, errors: reportErrors} = useQueryReportById(reportId);
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportHomePage");

  return (
    <WaitForData loading={reportLoading} errors={reportErrors} onLoaded={() =>
      <Page className="ReportHomePage">
        <Page.Title text={t("reporting:home_title")}/>
        <Page.Content className="spaced-children">
          {report ? <ReportHomeBlock reportId={reportId} jobId={report.jobId}/> : <Redirect to={ROUTES.NOT_FOUND}/>}
        </Page.Content>
      </Page>
    }/>
  );
}
