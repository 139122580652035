import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "reportById";

export const QUERY = gql`
  query ${QUERY_NAME}($id: ID!) {
    ${QUERY_NAME}(id: $id) {
      _id
      job {
        _id
        reports {
          _id
          date
          candidateCount
        }
      }
    }
  }
`;

export default function useQueryReportsForJob(reportId) {

  const variables = {id: reportId};

  const {data, ...status} = useWrappedQuery(QUERY, "", QUERY_NAME, {variables});

  // Return reports list directly, otherwise return null if any nested level is missing
  const reportsData = data && data.job && data.job.reports;

  // Return data directly (without its first GraphQL parent) and return other status info separately
  return ({data: reportsData, ...status});
}
